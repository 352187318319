<template>
  <a-config-provider size="large">
    <div class="attention-form" style="width: 100%;">
      <div class="head-tip">
        <p>提交意向后，工作人员会尽快联系您</p>
        <p>如果您现在就需要咨询，可拨打 010-62560919 与我们联系</p>

      </div>
      <div style="padding: 0 15px;">
        <div class="space-header">
          {{ title }}
        </div>
        <div class="header-subtitle">
          选择与我们同行，成为AI计算普惠价值的受益者
        </div>
        <a-form-model layout="vertical" ref="form" :model="formModel" :rules="formRule">
          <a-form-model-item label="业务意向" prop="attention">
            <a-radio-group size="large" v-model="formModel.attention" @change="handleAttentionChange">
              <a-radio value="production">
                产品
              </a-radio>
              <a-radio value="solution">
                行业解决方案
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="客户称呼" :max-length="10" prop="appellation">
            <a-input size="large" v-model="formModel.appellation" placeholder="如何称呼您" :max-length="50" />
          </a-form-model-item>
          <a-form-model-item label="所属公司" prop="companyName">
            <a-input size="large" v-model="formModel.companyName" placeholder="请输入您供职的公司" :max-length="50" />
          </a-form-model-item>
          <a-form-model-item label="职位名称" prop="position">
            <a-input size="large" v-model="formModel.position" placeholder="请输入您的职位" :max-length="50" />
          </a-form-model-item>
          <a-form-model-item label="所属部门" prop="department">
            <a-input size="large" v-model="formModel.department" placeholder="请输入您所在部门" :max-length="50" />
          </a-form-model-item>
          <a-form-model-item label="联系方式" prop="contact" class="phone">
            <div>
              <a-input size="large" v-model="formModel.contact.phoneNumber" placeholder="请输入单位电话或个人手机号"
                :max-length="20" />
              <a-input v-if="formModel.attention === 'production'" size="large" v-model="formModel.contact.email"
                placeholder="请输入您的电子邮箱" :max-length="50" style="margin-top: 20px" />
            </div>
          </a-form-model-item>
          <template v-if="formModel.attention === 'solution'">
            <a-form-model-item label="demo演示环境" prop="demo">
              <a-radio-group v-model="formModel.demo">
                <a-radio :value="true">
                  申请
                </a-radio>
                <a-radio :value="false">
                  不申请
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <template v-if="formModel.demo">
              <a-form-model-item label="功能描述" prop="funcDescription">
                <a-input size="large" v-model="formModel.funcDescription" placeholder="请描述您希望了解哪些功能" :max-length="50">
                  <div slot="suffix" class="suffix-text">
                    {{ formModel.funcDescription.length }}/50
                  </div>
                </a-input>
              </a-form-model-item>
              <a-form-model-item label="演示目标" prop="target">
                <a-input size="large" v-model="formModel.target" placeholder="请输入演示目标" :max-length="50">
                  <div slot="suffix" class="suffix-text">
                    {{ formModel.target.length }}/50
                  </div>
                </a-input>
              </a-form-model-item>
              <a-form-model-item label="项目需求" prop="projectRequire">
                <a-textarea v-model="formModel.projectRequire" :auto-size="{ minRows: 4, maxRows: 4 }" :max-length="100"
                  placeholder="如有具体项目需求，请填写" style="width: 100%;font-size: 16px;" />
                <div class="text-area-limit">
                  {{ formModel.projectRequire.length || 0 }}/100
                </div>
              </a-form-model-item>
            </template>

            <a-form-model-item label="解决方案资料" prop="needDoc">
              <a-radio-group v-model="formModel.needDoc">
                <a-radio :value="true">
                  申请
                </a-radio>
                <a-radio :value="false">
                  不申请
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <template v-if="formModel.needDoc">
              <a-form-model-item label="所需资料" prop="docList">
                <a-checkbox-group v-model="formModel.docList" :options="docOptions" />
              </a-form-model-item>
              <a-form-model-item label="接收邮箱" prop="receiveEmail">
                <a-input size="large" v-model="formModel.receiveEmail" placeholder="请输入用于接收资料的电子邮箱">
                </a-input>
              </a-form-model-item>
            </template>
          </template>
          <a-form-model-item label="意向留言" prop="message">
            <a-textarea v-model="formModel.message" :auto-size="{ minRows: 4, maxRows: 4 }" :max-length="100"
              placeholder="请输入您关于试用、购买产品或其他商业意愿的具体内容" style="width: 100%;font-size: 16px;" />
            <div class="text-area-limit">
              {{ formModel.message.length || 0 }}/100
            </div>
          </a-form-model-item>
        </a-form-model>
        <div style="display: flex; justify-content: center;">
          <a-button class="submit-btn" @click="handleIntentions" :loading="applyLoading">
            提交
          </a-button>
        </div>
      </div>
    </div>
  </a-config-provider>
</template>

<script>
import { Toast } from 'vant'
import pageMixin from '@/mixins/pageMixin'
import { regComplexPhone, regEmail2 } from '@/utils/regexp'
import { setDocumentTitle } from '@/utils/domUtil'

export default {
  mixins: [pageMixin],
  data () {
    return {
      applyLoading: false,
      formModel: {
        attention: 'production',
        appellation: '',
        companyName: '',
        demo: false,
        funcDescription: '',
        position: '',
        projectRequire: '',
        target: '',
        department: '',
        contact: {
          phoneNumber: '',
          email: ''
        },
        message: '',
        needDoc: false,
        receiveEmail: '',
        docList: []
      },
      docOptions: [{
        label: '解决方案',
        value: 0
      }, {
        label: '单页',
        value: 1
      }, {
        label: '白皮书',
        value: 2
      }, {
        label: '其他',
        value: 3
      }],
      formRule: {
        appellation: { required: true, message: '必填项' },
        companyName: { required: true, message: '必填项' },
        contact: { required: true, validator: this.contactValidator },
        funcDescription: { required: true, message: '必填项' },
        target: { required: true, message: '必填项' },
        docList: { required: true, message: '必填项' },
        receiveEmail: { required: true, validator: this.emailValidator }
        // position: { required: true, message: '请输入用户邮箱' },
        // department: { required: true, message: '请输入用户邮箱' },
      },
      title: '商务意向'
    }
  },
  created () {
    this.initFormModel()
  },
  methods: {
    initFormModel () {
      let attention = 'production'
      if (this.$route.query.bz === 'solution') {
        attention = 'solution'
      }
      this.formModel = {
        attention,
        appellation: '',
        companyName: '',
        demo: false,
        funcDescription: '',
        position: '',
        projectRequire: '',
        target: '',
        department: '',
        contact: {
          phoneNumber: '',
          email: ''
        },
        message: '',
        needDoc: false,
        receiveEmail: '',
        docList: []
      }
    },
    handleAttentionChange () {
      this.$refs.form.clearValidate()
    },
    emailValidator (rule, value, callback) {
      if (value) {
        if (
          regEmail2.test(value)
        ) {
          callback()
        } else {
          callback(new Error('请输入正确的邮箱'))
        }
      } else {
        callback(new Error('接收邮箱为必填项'))
      }
    },
    contactValidator (rule, value, callback) {
      if (this.formModel.attention === 'production') {
        if (value.phoneNumber === '' && value.email === '') {
          callback(new Error('联系方式的电话号码与电子邮箱至少填写一项'))
        } else if (value.phoneNumber) {
          if (
            regComplexPhone.test(value.phoneNumber.toString().replace(/\s/g, ''))
          ) {
            callback()
          } else {
            callback(new Error('请输入正确的电话号码'))
          }
        } else if (value.email) {
          if (
            regEmail2.test(value.email)
          ) {
            callback()
          } else {
            callback(new Error('请输入正确的邮箱'))
          }
        } else {
          callback()
        }
      } else if (value.phoneNumber === '') {
        callback(new Error('电话号码为必填项'))
      } else if (
        regComplexPhone.test(value.phoneNumber.toString().replace(/\s/g, ''))
      ) {
        callback()
      } else {
        callback(new Error('请输入正确的电话号码'))
      }
    },
    async handleIntentions () {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return
      }
      this.handleSubmit()
    },
    // 发起提交请求
    handleSubmit () {
      this.applyLoading = true
      const { submitFn, params, err } = this.useSubmit(this.formModel.attention)
      // 如果类型错误，则终端提交流程
      if (err) { this.applyLoading = false; return }
      submitFn(params)
        .then(res => {
          this.applyLoading = false
          Toast.success(
            '感谢！您的意向信息已成功提交至产品管理人员。核实信息后我们会尽快联系您。'
          )
          this.initFormModel()
        })
        .catch(e => {
          this.applyLoading = false
          Toast.fail('填写内容不符合规范，请重新填写后提交')
          // console.log(e)
        })
    },
    // 获取业务意向对应的提交方法和body体
    useSubmit (type) {
      let submitFn, params
      if (type === 'production') {
        submitFn = this.api.purchaseintentions
        params = {
          appellation: this.formModel.appellation,
          companyName: this.formModel.companyName,
          position: this.formModel.position,
          department: this.formModel.department,
          phoneNumber: this.formModel.contact.phoneNumber,
          email: this.formModel.contact.email,
          message: this.formModel.message
        }
      } else if (type === 'solution') {
        submitFn = this.api.submitSolution
        params = {
          customerInfo: {
            name: this.formModel.appellation,
            company: this.formModel.companyName,
            position: this.formModel.position,
            department: this.formModel.department,
            phone: this.formModel.contact.phoneNumber
          },
          remark: this.formModel.message
        }
        if (this.formModel.demo) {
          params = {
            ...params,
            ...{
              demoInfo: {
                featureDescription: this.formModel.funcDescription,
                purpose: this.formModel.target,
                requirement: this.formModel.projectRequire
              }
            }
          }
        }
        if (this.formModel.needDoc) {
          params = {
            ...params,
            ...{
              documentInfo: {
                documentTypes: this.formModel.docList,
                receiveEmail: this.formModel.receiveEmail
              }
            }
          }
        }
      } else {
        Toast.fail('业务意向错误')
        return { err: true }
      }
      return { submitFn, params }
    }
  },
  // 53客服引入，没有关闭访客端api，只能通过display:none来控制;轮询支持直接访问，最大10s
  beforeRouteEnter (to, from, next) {
    let delay = 10000
    const timer = setInterval(() => {
      const _53kf = document.getElementById('mobile_minchat_div')
      const footer = document.getElementsByClassName('ft_copyright')[0]
      if (_53kf && footer) {
        _53kf.classList.add('kf_53')
        footer.classList.add('kf_53_footer')
        clearInterval(timer)
      }
      delay -= 100
      if (delay <= 0) {
        clearInterval(timer)
      }
    }, 100)
    next()
  },
  beforeRouteLeave (to, from, next) {
    const _53kf = document.getElementById('mobile_minchat_div')
    const footer = document.getElementsByClassName('ft_copyright')[0]
    if (_53kf && footer) {
      _53kf.classList.remove('kf_53')
      footer.classList.remove('kf_53_footer')
    }
    next()
  },
  mounted () {
    if (this.$route.query.isTry) {
      this.title = '申请试用'
      setDocumentTitle('申请试用')
    }
  }
}
</script>

<style lang="less">
.attention-form {

  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 92px;
  }

  .ant-col.ant-form-item-label {
    label {
      color: rgba(0, 0, 0, 0.85);
      font-size: 30px;
    }

    label.ant-form-item-required::before {
      color: rgba(255, 77, 79, 1);
      font-size: 30px;
    }

    &::after {
      color: rgba(0, 0, 0, 0.85);
      font-size: 30px;
    }
  }

  .ant-form-item {
    // height: 70px;
    margin-bottom: 40px;
  }

  .ant-input {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .ant-form-item-label {
    height: 44px;
    margin-bottom: 16px;
    padding-bottom: 0;

    &::after {
      top: -1px;
    }
  }

  .ant-form-explain {
    text-align: right;
    font-size: 26px !important;
    position: absolute;
    right: 0;
  }
}
.kf_53 {
  display: none !important;
}
.kf_53_footer {
  padding: 20px 0 48px 0 !important;
}
</style>

<style lang="less" scoped>
.attention-form {
  .suffix-text {
    color: rgba(0, 0, 0, 0.45);
    font-size: 24px;
  }

  .text-area-limit {
    position: absolute;
    color: rgba(0, 0, 0, 0.45);
    font-size: 24px;
    right: 12px;
    bottom: 12px;
  }
  .head-tip {
    width: 750px;
    background: #fafafa;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    line-height: 40px;
    text-align: center;
    padding: 24px 0;
  }

  .space-header {
    text-align: center;
    height: 104px;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 104px;
    margin-top: 12px;
  }

  .header-subtitle {
    height: 96px;
    text-align: center;
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
  }

  .product-btn {
    .radius_button;
    margin: 50px auto 0;
    overflow: hidden;
    color: #fff;
  }

  .submit-btn {
    width: 100%;
    height: 80px;
    background: #0083ff;
    margin-top: 40px;
    margin-bottom: 60px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    cursor: pointer;
  }

  .liu_yan-label {
    color: rgba(0, 0, 0, 0.85);
    font-size: 30px;
  }

  .liu_yan-textarea {
    font-size: 28px;
    height: 240px;
  }
}
</style>
