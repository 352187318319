// export const regPhone = /^[1]([3-9])[0-9]{9}$/
export const regPhone = /^[0-9]{11}$/
export const regSmsCode = /^[0-9]{6}$/
export const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const regEmail2 = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
export const regName = /[\S]{2,}/
export const regInviCode = /^[\w,-]{36}$/
export const regComplexPhone = /^1[0-9]{10}$|^((\+86)|(86)|(0086))?(1)\d{10}$|^0(\d{2}(-|\s)?\d{4}(-|\s)?\d{4}|\d{3}(-|\s)?\d{3}(-|\s)?\d{4})((-|\s)?\d{1,4})?$/

// ~GPU
export const regGpuId = /^GPU-[a-zA-Z\-0-9]+/
